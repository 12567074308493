export default {
  forceEnvironment: false,
  vars: {
    staging: {
      api2: "https://api.catalufa.net",
      api: "https://staging.catalufa.net/api",
      // facebook_app_id: "1430274070568080",
      google_analytics: "UA-00000000000-7",
      google_client_id:
        "280439721594-7s4qjt1f645rcl9jae33dmu9rag9mphq.apps.googleusercontent.com",
      linkedin_key: "hje0pbtfch6y",
      recaptcha_site_key: "6LeTyfocAAAAAEwnfgQXaS4H7T2vKfs4LHoaVWkF",
      spreedly_key: "DSms22xgi3tkaNI4Rgq6S6tMwVr",
      stripe_public_key: "pk_test_NgBDTokHSqjslkb1bOzCtLjK",
    },
    production: {
      api2: "https://api.optixapp.com",
      api: "https://www.sharedesk.net/api",
      // facebook_app_id: "329732043893213",
      google_analytics: "UA-90531668-7",
      google_client_id: "482888596067.apps.googleusercontent.com",
      linkedin_key: "y8cbrcszn2rb",
      recaptcha_site_key: "6Lcp1_ocAAAAAFfK_lJA_yXPWKmlnplfKW2XCyOK",
      spreedly_key: "77SlEJt07tR7XBDp8A7DvVfYNqU",
      stripe_public_key: "pk_live_bYzwmSBY39wUDR9Q2rupqejH",
    },
    local: {
      api2: "http://api.sharedesk.local",
      api: "http://sharedesk.local/api",
      // facebook_app_id: "1430274070568080",
      google_analytics: "UA-00000000000-7",
      google_client_id:
        "280439721594-7s4qjt1f645rcl9jae33dmu9rag9mphq.apps.googleusercontent.com",
      linkedin_key: "hje0pbtfch6y",
      recaptcha_site_key: "6LeTyfocAAAAAEwnfgQXaS4H7T2vKfs4LHoaVWkF",
      spreedly_key: "DSms22xgi3tkaNI4Rgq6S6tMwVr",
      stripe_public_key: "pk_test_NgBDTokHSqjslkb1bOzCtLjK",
    },
  },
  getVar(varname) {
    let env = this.getEnv();
    if (this.vars[env] && this.vars[env][varname] != undefined) {
      return this.vars[env][varname];
    }
    return "";
  },
  getEnv() {
    if (this.forceEnvironment) {
      return this.forceEnvironment;
    }
    let domain = window.location.host.replace(/\:.+/, "");
    if (domain.match(/^((.+\.local)|localhost)$/)) {
      // Dev environment
      return "local";
    } else if (domain.match(/optixdev.com$/)) {
      return "staging";
    } else if (domain.match(/optixapp.com$/)) {
      return "production";
    } else {
      throw new Error("No environment detected");
    }
  },
};
