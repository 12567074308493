<template>
  <div>
    <optix-header v-if="!loadingData">{{
      isInquiryMode ? "Profile information" : "Populate your profile"
    }}</optix-header>
    <optix-container>
      <optix-content-layout v-if="loadingData">
        <optix-loading />
      </optix-content-layout>
      <optix-content-layout v-else>
        <optix-form ref="form">
          <p v-if="!isInquiryMode" class="mb-5">
            The information below will be included on your in-app community
            profile. You can change it or choose to hide it after you've created
            your account.
          </p>

          <optix-profile-image-upload
            v-if="isAvailable('image')"
            :label="'Profile photo' + (isRequired('image') ? '*' : '')"
            v-model="$optixConfiguration.user.data.image"
            accept="image/*"
            extensions="jpg,png,jpeg"
            :error-messages="errors.image"
            hint="Ensure your photo is centered when uploaded"
          />

          <optix-select
            v-if="
              isAvailable('primary_location') && isProfile('primary_location')
            "
            :label="
              'Primary location' + (isRequired('primary_location') ? '*' : '')
            "
            type="tel"
            :items="availablePrimaryLocations"
            item-text="name"
            item-value="location_id"
            v-model="$optixConfiguration.member.data.primary_location_id"
            hint="Choose where you will spend most of your time"
            persistent-hint
            :error-messages="errors.primary_location_id"
            :required="isRequired('primary_location')"
          />

          <optix-combobox
            v-if="isAvailable('skills')"
            :label="'Skills' + (isRequired('skills') ? '*' : '')"
            chips
            multiple
            deletable-chips
            hide-selected
            dont-fill-mask-blanks
            :items="skillItems"
            v-model="$optixConfiguration.user.data.skills"
            :search-input.sync="skillsSearchInput"
            :error-messages="errors.skills"
          />

          <optix-text-field
            v-if="isAvailable('city')"
            :label="'City name' + (isRequired('city') ? '*' : '')"
            v-model="$optixConfiguration.user.data.city"
            :error-messages="errors.city"
          />

          <optix-text-field
            v-if="isAvailable('company')"
            :label="'Company' + (isRequired('company') ? '*' : '')"
            hint="The name of the company or team you work with"
            v-model="$optixConfiguration.user.data.company"
            :error-messages="errors.company"
          />

          <!--optix-text-field
                v-if="isAvailable('profession')"
                label="Professional headline"
                v-model="$optixConfiguration.user.data.profession"
          :error-messages="errors.profession"/-->

          <optix-text-field
            v-if="isAvailable('title')"
            :label="'Position' + (isRequired('title') ? '*' : '')"
            v-model="$optixConfiguration.user.data.title"
            hint="Your current workplace title, e.g. Marketing Coordinator"
            :error-messages="errors.title"
          />

          <optix-textarea
            v-if="isAvailable('bio')"
            :label="'Short bio' + (isRequired('bio') ? '*' : '')"
            :rows="2"
            v-model="$optixConfiguration.user.data.bio"
            :error-messages="errors.bio"
          />

          <h4
            class="my-4"
            v-if="
              isAvailable('twitter') ||
                // isAvailable('facebook') ||
                isAvailable('linkedin') ||
                isAvailable('google')
            "
          >
            Social
          </h4>

          <optix-text-field
            v-if="isAvailable('twitter')"
            :label="'Twitter handle' + (isRequired('twitter') ? '*' : '')"
            v-model="$optixConfiguration.user.data.twitter"
            :error-messages="errors.twitter"
          />

          <!-- <optix-text-field
            v-if="isAvailable('facebook')"
            :label="'Facebook profile' + (isRequired('facebook') ? '*' : '')"
            v-model="$optixConfiguration.user.data.facebook"
            :error-messages="errors.facebook"
          /> -->

          <optix-text-field
            v-if="isAvailable('linkedin')"
            :label="'Linkedin page' + (isRequired('linkedin') ? '*' : '')"
            v-model="$optixConfiguration.user.data.linkedin"
            :error-messages="errors.linkedin"
          />

          <optix-text-field
            v-if="isAvailable('google')"
            :label="'Google+' + (isRequired('google') ? '*' : '')"
            v-model="$optixConfiguration.user.data.google"
            :error-messages="errors.google"
          />

          <div
            v-for="property_group in property_groups"
            :key="property_group.property_group_id"
            class="my-2"
          >
            <h4 class="my-4">{{ property_group.label }}</h4>
            <div
              v-for="property_field in property_fields[
                property_group.property_group_id
              ]"
            >
              <optix-property-field
                :ref="`prop${property_field.property_id}`"
                class="my-3"
                v-model="
                  property_values[property_group.property_group_id][
                    property_field.property_id
                  ]
                "
                :config="property_field"
                :key="property_field.property_id"
              />
            </div>
          </div>
        </optix-form>
      </optix-content-layout>
    </optix-container>
    <optix-footer-navigation>
      <optix-dot-progress slot="center" />
      <optix-btn
        slot="right"
        @click="next"
        :loading="nextLoading"
        :disabledStyle="loadingData"
        >Next</optix-btn
      >
    </optix-footer-navigation>
  </div>
</template>
<script>
import Validate from "@/mixins/Validate.js";
import forEach from "lodash/forEach";

export default {
  mixins: [Validate],
  data() {
    return {
      loadingData: true,
      skillsSearchInput: "",
      skillsSearchResults: [],
      nextLoading: false,
      profile_fields: {},
      property_groups: [],
      property_fields: {},
      property_values: {},
      possible_options: [
        "image",
        "city",
        "company",
        "title",
        "bio",
        "twitter",
        // "facebook",
        "linkedin",
        "google",
        "skills",
      ],
    };
  },
  mounted() {
    if (!this.$optixConfiguration.user.lookup.member_account) {
      this.$router.push({ name: "Lookup" });
      return;
    }

    let venue_profile_fields = this.$optixWS.call(
      "GET",
      "/venues/options/profileFields/",
      {
        params: {
          venue_id: this.$optixConfiguration.venue.venue_id,
        },
      }
    );

    let venue_properties = this.$optixWS.call("GET", "/properties/list/", {
      params: {
        venue_id: this.$optixConfiguration.venue.venue_id,
        reference_type: "venues_members",
      },
    });

    let member_properties = this.$optixWS.call("GET", "/properties/values/", {
      params: {
        venue_id: this.$optixConfiguration.venue.venue_id,
        reference_type: "venues_members",
        reference_id: this.$optixConfiguration.user.lookup.member_account,
        has_permission: 0,
      },
    });

    let user_basic_info = this.$optixWS.call(
      "GET",
      "/users/get/" + this.$optixConfiguration.user.lookup.user_account,
      { params: { scope: "basic" } }
    );

    let member_info = this.$optixWS.call(
      "GET",
      "/members/get/" + this.$optixConfiguration.user.lookup.member_account,
      { params: { scope: "primary_location" } }
    );

    let venue_info = this.$optixWS.call(
      "GET",
      "/venues/get/" + this.$optixConfiguration.venue.venue_id,
      { params: { scope: "locations", portal: "1" } }
    );

    Promise.all([
      venue_profile_fields,
      venue_properties,
      member_properties,
      user_basic_info,
      member_info,
      venue_info,
    ])
      .then((success) => {
        this.profile_fields = success[0].data.response;

        // User profile data
        Object.assign(
          this.$optixConfiguration.user.data,
          success[3].data.response
        );

        // Member data
        Object.assign(
          this.$optixConfiguration.member.data,
          success[4].data.response
        );

        this.$optixConfiguration.venue.locations = [];
        // Locations available
        success[5].data.response.locations.map((location) => {
          if (location.status == "1") {
            //location.location_id = parseInt(location.location_id);
            this.$optixConfiguration.venue.locations.push(location);
          }
        });

        // Filter properties, removing private fields and indexing public fields
        success[2].data.response.map((property_group) => {
          let property_fields = [];

          property_group.properties.map((property_field) => {
            if (
              property_field.is_public &&
              property_field.permissions[0] &&
              property_field.permissions[0].is_allowed == "1" &&
              (this.isInquiryMode
                ? parseInt(property_field.inquiry_display_policy) > 0
                : property_field.display_onboarding == "1")
            ) {
              property_fields.push(property_field);
            }
          });

          // property_group.properties.map((property_field) => {
          //   console.log(property_field.label)
          //   if (
          //     property_field.is_public
          //     // &&
          //     // property_field.permissions[0] &&
          //     // property_field.permissions[0].is_allowed == "1" &&
          //     // property_field.display_onboarding == "1"

          //   ) {
          //     property_fields.push(property_field);
          //   }
          // });

          if (property_fields.length) {
            this.property_fields[
              property_group.property_group_id
            ] = property_fields;
            this.property_values[property_group.property_group_id] = {}; // Create empty node for values
            this.property_groups.push(property_group);
          }
        });

        // Index current property values
        success[2].data.response.map((property_group) => {
          let property_values = {};
          property_group.properties.map((property_field) => {
            if (property_field.type == "multiple") {
              let values = [];
              property_field.values.map((value) => {
                values.push(value.property_option_id);
              });
              property_values[property_field.property_id] = values;
            } else if (property_field.type == "select") {
              property_values[property_field.property_id] = property_field
                .values[0]
                ? property_field.values[0].property_option_id
                : "";
            } else {
              property_values[property_field.property_id] = property_field
                .values[0]
                ? property_field.values[0].value
                : "";
            }
          });

          this.property_values[
            property_group.property_group_id
          ] = property_values;
        });

        // If no profile fields are required, go ahead to details page
        let possible_options_counter = 0;
        if (
          this.isAvailable("primary_location") &&
          this.isProfile("primary_location")
        ) {
          possible_options_counter++;
        }
        this.possible_options.map((option) => {
          if (this.isAvailable(option) != 0) {
            possible_options_counter++;
          }
        });
        if (possible_options_counter == 0 && this.property_groups.length == 0) {
          if (this.backAction) {
            this.$router.push({ name: "Lookup" });
          } else {
            this.$router.push({ name: "Details" });
          }
        }
        this.loadingData = false;
      })
      .catch((error) => {
        this.$optixConfiguration.showSystemError(
          "Error retrieving profile",
          error
        );
      });
  },
  watch: {
    // Search for skills based on tiping
    skillsSearchInput(newValue) {
      let results = [];
      if (
        newValue &&
        this.$optixConfiguration.user.data.skills &&
        this.$optixConfiguration.user.data.skills.indexOf(newValue) === -1
      ) {
        results.push(newValue);
      }

      this.$optixWS
        .call(
          "GET",
          "/users/availableSkills",
          {},
          {
            venue_id: this.$optixConfiguration.venue.venue_id,
            search: newValue,
          }
        )
        .then((success) => {
          success.data.response.map((skill) => {
            results.push(skill.skill);
          });
          this.skillsSearchResults = results;
        });
    },
  },
  computed: {
    // Return skill items suggested, removing duplicated
    skillItems() {
      let options = [];
      this.skillsSearchResults.map((option) => {
        let duplicate = false;
        if (this.$optixConfiguration.user.data.skills) {
          this.$optixConfiguration.user.data.skills.map((selected_option) => {
            if (selected_option.toLowerCase() == option.toLowerCase()) {
              duplicate = true;
            }
          });
        }
        if (!duplicate) {
          options.push(option);
        }
      });
      return options;
    },
    availablePrimaryLocations() {
      if (this.isRequired("primary_location")) {
        return this.$optixConfiguration.venue.locations;
      }
      return [{ location_id: null, name: "None" }].concat(
        this.$optixConfiguration.venue.locations
      );
    },
    backAction() {
      let urlParams = new URLSearchParams(window.location.search);
      return urlParams.get("back") == "true";
    },
    isInquiryMode() {
      return window.location.href.includes("inquiry");
    },
  },
  methods: {
    isProfile(field_name) {
      return this.profile_fields.fields.some(
        (field) =>
          field.field == field_name &&
          field.display_onboarding &&
          field.display_profile
      );
    },
    isRequired(field_name) {
      if (this.isInquiryMode) {
        return this.profile_fields.fields.some(
          (field) =>
            field.field == field_name && field.inquiry_display_policy == 2
        );
      }
      return this.profile_fields.fields.some(
        (field) =>
          field.field == field_name &&
          field.display_onboarding &&
          field.is_required
      );
    },
    isAvailable(field_name) {
      if (this.isInquiryMode) {
        return this.profile_fields.fields.some(
          (field) =>
            field.field == field_name && field.inquiry_display_policy !== 0
        );
      }
      return this.profile_fields.fields.some(
        (field) => field.field == field_name && field.display_onboarding
      );
    },
    // propertyAvailableForInquiry(property_field){

    // },
    // propertyAvailableForSignip(property_field){

    // },
    // parseInt(str){
    //   return parseInt(str)
    // },
    back() {
      this.$router.push({ name: "Lookup" });
    },
    next() {
      const extra_promises = [];
      // Trigger validations for property fields
      forEach(this.property_fields, (fields) => {
        fields.map((field) => {
          if (
            this.$refs[`prop${field.property_id}`] &&
            this.$refs[`prop${field.property_id}`][0]
          ) {
            extra_promises.push(
              this.$refs[`prop${field.property_id}`][0].validateField()
            );
          }
        });
      });

      let validations = {};
      let possible_validations = {
        city: [Validate.rules.required],
        company: [Validate.rules.required],
        profession: [Validate.rules.required],
        title: [Validate.rules.required],
        bio: [Validate.rules.required],
        twitter: [Validate.rules.required],
        // facebook: [Validate.rules.required],
        linkedin: [Validate.rules.required],
        google: [Validate.rules.required],
        image: [
          (image) => {
            if (image && !image.match(/sd_avatar_blue/)) {
              return;
            }
            return "This field is required";
          },
        ],
        skills: [Validate.rules.required],
      };
      this.possible_options.map((option) => {
        if (this.isAvailable(option) && this.isRequired(option)) {
          validations[option] = possible_validations[option];
        }
      });

      if (
        this.isAvailable("primary_location") &&
        this.isProfile("primary_location")
      ) {
        if (this.isRequired("primary_location")) {
          validations["primary_location_id"] = [Validate.rules.required];
        } else {
          validations["primary_location_id"] = [
            Validate.rules.optional([Validate.rules.required]),
          ];
        }
      }

      this.validateForm(
        {
          ...this.$optixConfiguration.user.data,
          primary_location_id: this.$optixConfiguration.member.data
            .primary_location_id,
        },
        validations,
        extra_promises
      )
        .then((success) => {
          this.nextLoading = true;

          let save_promises = [];
          // Trigger validations for property fields
          forEach(this.property_fields, (fields) => {
            fields.map((field) => {
              if (
                this.$refs[`prop${field.property_id}`] &&
                this.$refs[`prop${field.property_id}`][0]
              ) {
                save_promises.push(
                  this.$refs[`prop${field.property_id}`][0].saveValue()
                );
              }
            });
          });

          let update_promise = this.$optixWS.call(
            "POST",
            "/users/update/",
            {},
            {
              user_id: this.$optixConfiguration.user.data.user_id,
              city: this.$optixConfiguration.user.data.city,
              company: this.$optixConfiguration.user.data.company,
              //profession: this.$optixConfiguration.user.data.profession,
              title: this.$optixConfiguration.user.data.title,
              bio: this.$optixConfiguration.user.data.bio,
              twitter: this.$optixConfiguration.user.data.twitter,
              // facebook: this.$optixConfiguration.user.data.facebook,
              linkedin: this.$optixConfiguration.user.data.linkedin,
              google: this.$optixConfiguration.user.data.google,
              skills: this.$optixConfiguration.user.data.skills,
            }
          );

          let hasImage = false;
          if (
            this.$optixConfiguration.user.data.image &&
            !this.$optixConfiguration.user.data.image.match(/^http/)
          ) {
            let image_promise = this.$optixWS.call(
              "POST",
              "/users/picture/mobile/",
              {},
              {
                file: this.$optixConfiguration.user.data.image.substr(
                  this.$optixConfiguration.user.data.image.indexOf(",") + 1
                ),
              }
            );
            save_promises.push(image_promise);
            hasImage = true;
          }

          if (
            this.isAvailable("primary_location") &&
            this.isProfile("primary_location")
          ) {
            let primary_location_promise = this.$optixWS.graphQL(
              `mutation accountUpdate($user_id: ID!, $organization_id: ID!, $primary_location_id: ID, $set_no_primary_location: Boolean ){
              accountUpdate(organization_id: $organization_id, account:{user_id: $user_id,  organization_id: $organization_id } primary_location_id: $primary_location_id set_no_primary_location:$set_no_primary_location){
                account_id
                name
                primary_location {
                  location_id
                  name
                }
              }
            }`,
              {
                user_id: this.$optixConfiguration.user.data.user_id,
                organization_id: this.$optixConfiguration.venue.venue_id,
                ...(this.$optixConfiguration.member.data.primary_location_id > 0
                    ? { primary_location_id: this.$optixConfiguration.member.data.primary_location_id }
                    : { set_no_primary_location: true })
              },
              "accountUpdate"
            );
            save_promises.push(primary_location_promise);
          }

          Promise.all([update_promise].concat(save_promises))
            .then((success) => {
              this.nextLoading = false;
              if (hasImage) {
                let imageResult = success.pop();
                if (
                  imageResult &&
                  imageResult.data &&
                  imageResult.data.response &&
                  imageResult.data.response.picture_url
                ) {
                  this.$optixConfiguration.user.data.image =
                    imageResult.data.response.picture_url;
                }
              }
              this.$router.push({ name: "Details" });
            })
            .catch((error) => {
              this.nextLoading = false;
              if (error && error.length > 0) {
                this.$optixConfiguration.showSystemError("Lookup error", error);
              }
            });
        })
        .catch((e) => {
          this.$refs.form.focusErrors();
        });
    },
  },
};
</script>
