<template>
  <div>
    <optix-header>Get started</optix-header>
    <optix-container>
      <optix-content-layout v-if="autoLogin">
        <optix-loading />
      </optix-content-layout>
      <optix-content-layout v-else>
        <p class="mb-4 mt-2">
          It looks like you already have an account. Please enter your password
          below.
        </p>
        <p class="mb-5 mt-2">
          If you're stuck, you can
          <router-link :to="{ name: 'RecoverPassword' }"
            >reset your password</router-link
          >.
        </p>
        <optix-form ref="form">
          <optix-text-field
            label="Password"
            ref="password"
            name="password"
            id="password"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'visibility_off' : 'visibility'"
            v-model="user.password"
            @click:append="showPassword = !showPassword"
            :error-messages="errors.password"
            @keydown.enter.prevent="next"
            :hint="passwordHint"
          />
        </optix-form>
      </optix-content-layout>
    </optix-container>
    <optix-footer-navigation>
      <optix-btn slot="left" @click="$router.push({ name: 'Lookup' })" flat
        >Back</optix-btn
      >
      <optix-dot-progress slot="center" />
      <optix-btn slot="right" @click="next" :loading="nextLoading"
        >Next</optix-btn
      >
    </optix-footer-navigation>
  </div>
</template>
<script>
import Validate from "@/mixins/Validate.js";

export default {
  mixins: [Validate],
  props: {},
  data() {
    return {
      autoLogin: false,
      showPassword: false,
      nextLoading: false,
      user: {
        password: "",
      },
    };
  },
  mounted() {
    this.$refs.password.focus();

    if (this.$optixConfiguration.autoPassword !== false) {
      this.autoLogin = true;
      this.user.password = this.$optixConfiguration.autoPassword;
      this.$optixConfiguration.autoPassword = false;
      this.next();
    }
  },
  computed: {
    passwordHint() {
      if (this.user.password.length == 0) {
        return "Enter your password";
      } else if (this.user.password.length < 8) {
        return "Password must be at least 8 characters";
      } else {
        return "";
      }
    },
  },
  watch: {},
  methods: {
    next() {
      const rules = {
        password: [Validate.rules.password],
      };
      const extra_promises = [
        this.checkPasswordStrength("password", this.user.password),
      ];
      this.validateForm(this.user, rules, extra_promises)
        .then((success) => {
          this.nextLoading = true;
          this.$optixWS
            .call(
              "POST",
              "/auth/login/",
              {},
              {
                venue_id: this.$optixConfiguration.venue.venue_id,
                password: this.user.password,
                email: this.$optixConfiguration.user.data.email,
                member_type: "onboarding",
              }
            )
            .then((success) => {
              if (typeof success.data.response === "string") {
                this.addError("password", success.data.response);
              } else {
                this.$optixConfiguration.user.data =
                  success.data.response.user_data;
                this.$optixWS.access_token = success.data.response.access_token;
                this.$optixConfiguration.user.access_token =
                  success.data.response.access_token;
                if (this.$optixConfiguration.user.lookup.user_status == 2) {
                  // Pending user, show the basic info interface
                  this.$router.push({ name: "BasicInfo" });
                } else if (
                  this.$optixConfiguration.user.lookup.member_status == false
                ) {
                  // This should never happen
                  // Error
                  this.$optixConfiguration.showSystemError(
                    "Member status false",
                    this.$optixConfiguration.user
                  );
                } else if (
                  this.$optixConfiguration.user.lookup.member_status != 1
                ) {
                  this.$router.push({ name: "Profile" });
                } else {
                  // Its a member? Go to last interface...
                  this.$router.push({ name: "Complete" });
                }
              }
              this.nextLoading = false;
            })
            .catch((error) => {
              try {
                this.addError("password", error.response.data.response);
              } catch (e) {
                this.$optixConfiguration.showSystemError("Login error", error);
              }
              this.nextLoading = false;
              this.autoLogin = false;
              this.$refs.form.focusErrors();
            });
        })
        .catch((error) => {
          this.$refs.form.focusErrors();
        });
    },
  },
};
</script>
