<template>
  <div>
    <optix-header>Create your account</optix-header>
    <optix-container>
      <optix-content-layout v-if="loadingData">
        <optix-loading />
      </optix-content-layout>
      <optix-content-layout v-else>
        <optix-form ref="form">
          <p class="mb-5 mt-2">
            {{ description }}
          </p>
          <optix-text-field
            ref="name"
            label="First name"
            v-model="user.name"
            name="name"
            id="name"
            @keydown.enter.prevent="$refs.surname.focus()"
            :error-messages="errors.name"
          />
          <optix-text-field
            ref="surname"
            label="Last name"
            v-model="user.surname"
            name="surname"
            id="surname"
            @keydown.enter.prevent="$refs.password.focus()"
            :error-messages="errors.surname"
          />
          <optix-text-field
            ref="password"
            v-if="!isInquiryMode"
            label="Set a password"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'visibility_off' : 'visibility'"
            v-model="user.password"
            name="password"
            id="password"
            @click:append="showPassword = !showPassword"
            :error-messages="errors.password"
            @keydown.enter.prevent="next"
            :hint="
              user.password.length < 8
                ? 'Password must be at least 8 characters'
                : ''
            "
          />
        </optix-form>
      </optix-content-layout>
    </optix-container>
    <optix-footer-navigation>
      <optix-dot-progress slot="center" />
      <optix-btn
        slot="right"
        @click="next"
        :loading="nextLoading"
        :disabledStyle="loadingData"
        >Next</optix-btn
      >
    </optix-footer-navigation>
  </div>
</template>
<script>
import Validate from "@/mixins/Validate.js";
import Steps from "@/mixins/Steps";

export default {
  mixins: [Validate, Steps],
  computed: {
    description() {
      return this.isInquiryMode
        ? "Please fill out your name below."
        : "Set up an account by filling out your name and setting a password below.";
    },
  },
  data() {
    return {
      loadingData: false,
      showPassword: false,
      nextLoading: false,
      user: {
        name: "",
        surname: "",
        password: "",
      },
    };
  },
  mounted() {
    if (!this.$optixConfiguration.user.lookup.member_account) {
      this.$router.push({ name: "Lookup" });
      return;
    }

    this.$nextTick(() => {
      this.$refs.name.focus();
    });
  },
  watch: {},
  methods: {
    next() {
      const rules = {
        name: [Validate.rules.required],
        surname: [Validate.rules.required],
      };
      const extra_promises = [];
      if (!this.isInquiryMode) {
        rules.password = [Validate.rules.password];
        extra_promises.push(
          this.checkPasswordStrength("password", this.user.password)
        );
      }
      this.validateForm(this.user, rules, extra_promises)
        .then((success) => {
          this.nextLoading = true;
          let { name, surname, password } = this.user;

          this.$optixWS
            .call(
              "POST",
              "/users/registration/complete/",
              {},
              {
                email: this.$optixConfiguration.user.data.email,
                name,
                surname,
                password,
                member_type: "onboarding",
                is_inquiry_mode: this.isInquiryMode,
              }
            )
            .then((success) => {
              // Authenticates the user if OK
              if (success.data.response && success.data.response.access_token) {
                this.$optixConfiguration.user.data = success.data.response.user;

                this.$optixWS.access_token = success.data.response.access_token;
                this.$optixConfiguration.user.access_token =
                  success.data.response.access_token;

                this.$router.push({ name: "Profile" });
                this.nextLoading = false;
              } else {
                this.nextLoading = false;
                if (
                  success.data.response.error == "User status is not PENDING"
                ) {
                  // Redirect to login
                  this.$router.push({ name: "Lookup" });
                } else {
                  this.addError("name", success.data.response.error);
                }
              }
            })
            .catch((error) => {
              this.nextLoading = false;
              this.$optixConfiguration.showSystemError(
                "Error saving user",
                error
              );
            });
        })
        .catch((e) => {
          this.$refs.form.focusErrors();
        });
    },
  },
};
</script>
